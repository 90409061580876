
import Vue from "vue";
import Clappr from "@clappr/player";
import PlaybackRatePlugin from "clappr-playback-rate-plugin";

export default Vue.extend({
    name: "VideoModal",
    data() {
        return {
            player: {
                destroy: () => null
            }
        };
    },
    computed: {
        visible: {
            get(): boolean {
                return this.$store.state.modals.video.visible;
            },
            async set(visible: boolean): Promise<void> {
                await this.$store.dispatch("modals/set", {
                    type: "video",
                    visible
                });
            }
        },
        info(): any {
            return this.$store.state.modals.video.data || {};
        },
        title(): string {
            return `${this.$t("video.title.video", {
                level: this.info.level,
                part: this.info.part,
                video: this.info.index + 1
            })}`;
        }
    },
    methods: {
        playVideo() {
            this.player = new Clappr.Player({
                plugins: [
                    PlaybackRatePlugin
                ],
                playbackRateConfig: {
                    defaultValue: 1,
                    options: [
                        {value: 1, label: "1x"},
                        {value: 1.25, label: "1.25"},
                        {value: 1.5, label: "1.5"},
                        {value: 1.75, label: "1.75"},
                        {value: 2, label: "2"},
                    ],
                },
                source: this.info.link,
                parentId: "#player",
                width: 860,
                height: 485
            });
        },
        destroyVideo() {
            this.player.destroy();
        }
    }
});
