
import Vue from "vue";
import {UserInfo} from "@/types.d";
import VacationModal from "@/components/modals/VacationModal.vue";
import MaterialsModal from "@/components/modals/MaterialsModal.vue";

export default Vue.extend({
  name: "ProfileInfo",
  components: {
    VacationModal,
    MaterialsModal
  },
  data() {
    return {
      vacationModalVisible: false
    };
  },
  computed: {
    user(): UserInfo {
      return this.$store.state.user.me;
    },
    reviewDeadlineClass() {
      const weeksToDeadline = (this.user.reviewRegistrationDeadlineTS - (Date.now() / 1000)) / 60 / 60 / 24 / 7;

      return weeksToDeadline <= 1
        ? "has-text-danger"
        : weeksToDeadline === 2
          ? "has-text-warning"
          : "green";
    },
    reviewDeadlineFormatted() {
      return this.$dt
          .fromSeconds(+this.user.reviewRegistrationDeadlineTS)
          .toLocaleString({month: "long", day: "numeric"});
    }
  },
  methods: {
    async requestDiscordHelp() {
      if (this.$store.state.user.helpRequested) {
        this.$message({
          type: "warning",
          message: this.$t("support.warning").toString()
        });
        return;
      }

      await this.$store.dispatch("user/requestDiscordHelp", {successMessage: this.$t("support.success")});
    }
  }
});
