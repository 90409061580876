
import Vue from "vue";
import {download} from "@/utils";

export default Vue.extend({
  name: "MarkedLink",
  props: {
    href: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "info"
    },
    level: {
      type: Number,
      default: 1
    },
    isText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isExternalLink(): boolean {
      return this.href.includes("http");
    },
    link(): string | null  {
      return this.href.startsWith("downloads")
        ? null
        : this.isExternalLink
          ? this.href
          : "/material/" + this.level + "/" + encodeURIComponent(this.href);
    }
  },
  methods: {
    async justClickOrDownload(e: MouseEvent) {
      if (this.href.startsWith("downloads")) {
        e.preventDefault();
        const {data: {data: {link}}} = await this.$store.dispatch("materials/getFile", {
            level: this.level,
            filename: this.href
        });
        download(link, this.href.split("/").pop() + "");
      } else {
        this.$emit("click");
      }
      return false;
    }
  }
});
