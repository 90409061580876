
import {commentMinLength, systemAdminEmail, links} from "@/config";
import Vue from "vue";
import MarkedLink from "@/components/MarkedLink.vue";
import {ActiveLevel, Mark, ReviewInfo, ReviewParticipant} from "@/types";

const initialFormState = {
  mark: 0,
  comment: "",
  reviewDuration: 0,
  inflated: false,
  thesesAnswers: []
};

export default Vue.extend({
  name: "EvaluateModal",
  components: {
    MarkedLink
  },
  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.modals.evaluation.visible;
      },
      async set(value: boolean): Promise<void> {
        await this.$store.dispatch("modals/set", {
          type: "evaluation",
          visible: value
        });
      }
    },
    theses() {
      return this.$store.state.review.theses;
    },
    info(): any {
      return this.$store.state.modals.evaluation.data || {};
    },
    roles(): string[] {
      return this.$store.state.user.me.role || [];
    },
    examineLevel(): number {
      return this.roles.includes("volunteer") ? this.info.receiverLevel : this.info.authorLevel;
    },
    partnerContacts() {
      return this.$store.state.user.users[this.info.receiverId] || {};
    },
    progress(): number {
      return Math.min((+this.form.comment.length) / commentMinLength * 100, 100) || 0;
    },
    isBadMark() {
      return this.evaluationType === "code" && (this.form.mark === 2 || (this.form.mark === 3 && this.form.inflated));
    },
    title(): string {
      const review = !isNaN(+this.info.reviewNumber) ? `${this.$t("reviewMark.reviewNumber", {reviewNumber: this.info.reviewNumber + 1})}` : "";
      // const review = this.info.reviewNumber ? `${this.$t("reviewMark.reviewNumber", {reviewNumber: this.info.reviewNumber+1})}`: "";
      const task: string = this.info.taskNumber ? `${this.$t("reviewMark.task", {task: this.info.taskNumber})}` : "";
      const level: string = this.info.feedbackType === 0
          ? `${this.$t("reviewMark.level", {level: this.info.authorLevel})}`
          : this.info.receiverLevel
              ? `${this.$t("reviewMark.level", {level: this.info.receiverLevel})}`
              : "";
      const partnerName = `${this.partnerContacts.firstName} ${this.partnerContacts.lastName}`;
      const action: string = this.info.change ? `${this.$t("reviewMark.changeMark")}` : `${this.$t("reviewMark.evaluate")}`;

      // Виставити оцінку Олексій Смілянський за рев'ю на 3 рівні
      // Виставити оцінку Олексій Смілянський за рішення 4 завдання на 3 рівні (1 рев'ю)
      // Змінити оцінку Олексій Смілянський за рішення 4 завдання на 3 рівні (1 рев'ю)
      return `${action} ${partnerName} за ${task || this.$t("review").toString()} ${level} ${review}`;
    },
    evaluationType(): string {
      if (!this.info.taskNumber) {
        return "review";
      }
      return "code";
    },
    markDetailsVisible(): boolean {
      return this.evaluationType === "code" && ([2, 3].includes(this.form.mark));
    }
  },
  data() {
    return {
      timeout: null,
      hint: "",
      links,
      reviewDurationDisabled: false,
      form: Object.assign({}, initialFormState),
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        // get random invisible feedback hint
        this.hint = [...this.$t("reviewMark.hints")][Math.floor(Math.random() * this.$t("reviewMark.hints").length)];

        // init previous reviewDuration value
        if (this.info.reviewDuration) {
          this.$set(this.form, "reviewDuration", this.info.reviewDuration);
          this.reviewDurationDisabled = true;
        }

        if (this.info.change && this.info.feedbackType === undefined) {
          const level: ActiveLevel = this.$store.getters["levels/getLevel"](this.examineLevel);
          const examine: ReviewParticipant = level.examinees.find((u: ReviewParticipant) => u.id === this.info.receiverId) || level.examinees[0];
          const review: ReviewInfo = examine.reviews[this.info.reviewNumber];
          const prevMark: Mark = review.marks.find((m: Mark) => m.task === this.info.taskNumber) || review.marks[0];
          this.$set(this.form, "mark", prevMark.mark);
          this.$set(this.form, "comment", prevMark.message);
        }
      } else {
        this.hint = "";
        this.reviewDurationDisabled = false;
        this.form = Object.assign({}, initialFormState);
      }
    }
  },
  methods: {
    debounceChange(val: number) {
      if (this.timeout) { clearTimeout(this.timeout); }
      this.timeout = setTimeout(() => {
        this.$set(this.form, "reviewDuration", val);
      }, 300);
    },
    async evaluate() {
      // validating form
      if (this.form.mark === 0) {
        await this.$message.error(this.$t("reviewWarnings.chooseMark").toString());
        return;
      }
      if (this.evaluationType === "review") {
        if (this.form.reviewDuration === undefined || this.form.reviewDuration === null) {
          await this.$message.error(this.$t("reviewWarnings.setDuration").toString());
          return;
        } else if (`${this.form.reviewDuration}` === "0") {
          try {
            await this.$confirm("Ви впевнені, що тривалість дзвінка на ревʼю була 0 хвилин?", "", {
              confirmButtonText: "Так, дзвінка не було",
              cancelButtonText: "Ні, зараз вкажу тривалість",
              type: "warning"
            });
          } catch (e) {
            // cancel form submit
            return;
          }
        }
      }
      if (this.form.comment.length === 0) {
        await this.$message.error(this.$t("reviewWarnings.specifyComment").toString());
        return;
      }
      if (this.form.comment.length < commentMinLength) {
        await this.$message.error(this.$t("reviewWarnings.shortComment").toString());
        return;
      }
      if (this.form.mark !== 3) {
          this.$set(this.form, "inflated", false);
      }
      if (!this.isBadMark) {
          this.$set(this.form, "thesesAnswers", []);
      }
      if (this.isBadMark && this.form.thesesAnswers.length === 0 && this.evaluationType === "code") {
        this.$message.error(this.$t("reviewWarnings.twoReason", {systemAdminEmail}).toString());
      }
      this.$set(this.form, "thesesAnswers", this.$store.state.review.theses.map((thesesId: any) =>
        ({id: thesesId.id, checked: (this.form.thesesAnswers as number[]).includes(thesesId.id)})
      ));
      const postData = {
          ...this.info,
          ...this.form,
      };
      if (!isNaN(this.info.reviewNumber)) {
          postData.reviewNumber = this.info.reviewNumber + 1;
      }
      await this.$store.dispatch("review/setMark", postData);
      await this.$store.dispatch("modals/set", {
        type: "evaluation",
        visible: false,
        data: {}
      });
      this.form = Object.assign({}, initialFormState);
    }
  }
});
