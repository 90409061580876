
import Vue from "vue";
import {ActiveLevel, Level} from "@/types";
import MarkedLink from "../MarkedLink.vue";
import {commentMinLength} from "@/config";
import {endOfDay, inRange, secondsInDay} from "@/utils";

export default Vue.extend({
  name: "MeetUpFeedbackModal",
  computed: {
    modalWidth(): string {
      return Math.min(700, window.innerWidth - 30) + "px";
    },
    progress(): number {
      return Math.min((+this.message.length) / commentMinLength * 100, 100) || 0;
    },
    // how many days passed from the start of studying
    studyDay(): number {
      return (+this.$dt.local().toSeconds() - this.$store.state.user.me.startStudyDateTS) / secondsInDay;
    },
    visible(): boolean {
      if (this.$cookies.get("hideFeedbackModal")) {
        return false;
      }

      if (this.lastFeedbackDate === 0) {
          return false;
      }

      const feedbackDate: number = this.$dt.fromSeconds(this.lastFeedbackDate || 0).startOf("day").toSeconds();
      const feedbackDay: number = (feedbackDate - this.$store.state.user.me.startStudyDateTS) / secondsInDay;
      let showModal = // inRange(this.studyDay, 1.815, 3) && !inRange(feedbackDay, 1, 3);
          this.$store.state.config.obligatedMeetings
          // please don't care about this formula
          // it is used to get the yesterdays 10pm time and till the end of the next day
          .some((d: number) =>
              inRange(this.studyDay, d - 0.085, +d + 1)
              && !inRange(feedbackDay, d - 1, +d + 1)
          );

      const levelHasMeetUpsToday = (day: number): boolean =>
        this.meetUps.includes(day) && (this.$dt.local().hour > 22);
      const levelHadMeetUpsYesterday = (day: number): boolean => this.meetUps.includes(day - 1);

      const startOfToday = this.$dt.local().startOf("day").toSeconds();
      const startOfYesterday = this.$dt.local().minus({days: 1}).startOf("day").toSeconds();
      const endOfToday = endOfDay(); // this.$dt.local().endOf("day").toSeconds();

      this.activeLevels.forEach((l: ActiveLevel): void => {
        const meetUpWasToday = levelHasMeetUpsToday(l.day);
        const meetUpWasYesterday = levelHadMeetUpsYesterday(l.day);
        if (meetUpWasToday || meetUpWasYesterday) {
          if (
            !(
              (meetUpWasToday && inRange(feedbackDate, startOfToday, endOfToday)) ||
              (meetUpWasYesterday && inRange(feedbackDate, startOfYesterday, endOfToday))
            )
          ) {
            showModal = true;
            this.meetUpDayOfWeek = meetUpWasToday ? `${this.$t("today")}` : `${this.$t("yesterday")}`;
            this.meetUpIndex = this.meetUps.indexOf(l.day - 1 + (+meetUpWasToday));
          }
        }
      });


      return this.independentVisibility && showModal;
    },
    meetUps(): number[] {
      return this.$store.state.config.meetUps;
    },
    lastFeedbackDate(): number {
      return this.$store.state.user.now.lastFeedbackTS;
    },
    activeLevels(): ActiveLevel[] {
      return this.$store.state.levels.all.filter((l: Level): boolean => l._opened);
    },
    FEEDBACK_FORM_URL(): string {
        return process.env.VUE_APP_FEEDBACK_FORM_URL || "";
    }
  },
  components: {
    MarkedLink
  },
  data() {
    return {
      independentVisibility: true,
      meetUpDayOfWeek: "",
      meetUpDate: "",
      meetUpIndex: 0,
      status: 0,
      mark: 0,
      message: ""
    };
  },
  methods: {
    async sendFeedback(): Promise<void> {
      if (this.status === 0) {
        this.$message.error(`${this.$t("meetUpFeedback.error.emptyStatus")}`);
        return;
      }
      if (this.status === 3 && this.mark === 0) {
        this.$message.error(`${this.$t("meetUpFeedback.error.emptyMark")}`);
        return;
      }
      if (this.status === 3 && (this.message === "" || this.message.length < 50)) {
        this.$message.error(`${this.$t("meetUpFeedback.error.emptyMessage")}`);
        return;
      }
      this.independentVisibility = false;

      if (this.status === 2) {
         this.$cookies.set("hideFeedbackModal", true, "1h");
      } else {
        await this.$store.dispatch("user/sendFeedback", {
          status: this.status,
          mark: this.mark,
          message: this.message,
          meetingNumber: this.meetUpIndex
        });
      }
    }
  }
});
