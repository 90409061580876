
import Vue from "vue";
import {links} from "@/config";
import MarkedLink from "@/components/MarkedLink.vue";
import {TranslateResult} from "vue-i18n";

export default Vue.extend({
  name: "WeekPlan",
  components: {
    MarkedLink
  },
  computed: {
    level(): number {
      return this.$store.state.user.me.level;
    },
    plan(): ({
      [index: number]: string[]
    }) {
      return this.$store.state.user.weekPlan;
    },
    planMessages(): Array<{ day: TranslateResult, date: string, message: string }> {
      const temp: Array<{ day: TranslateResult, date: string, message: string }> = []; // todo seems not that good
      Object.entries(this.plan).forEach((e) => {
        e[1].forEach((p) => {
          temp.push({
            day: e[0],
            date: this.getDayDate(parseInt(e[0], 10)),
            message: p
          });
        });
      });
      return temp;
    },
    links(): {
      [index: string]: string
    } {
      return links;
    },
    vacation(): string {
      return this.$store.state.user.now.vacation.status;
    }
  },
  data() {
    return {
      schemaVisible: false
    };
  },
  methods: {
    getDayDate(n: number): string {
      const d = new Date();
      d.setDate(d.getDate() - d.getDay() + n);
      return this.$dt.fromSeconds(+d / 1000).toLocaleString({month: "long", day: "numeric"});
    }
  }
});
