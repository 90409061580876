
import AssetLinks from "./LevelAssetLinks.vue";
import {ActiveLevel, Id, Lecture, Level, Material, SystemConfig, VideoPart} from "@/types.d";
import LevelReviewRegistration from "./LevelReviewRegistration.vue";
import LevelReviewHomeWorksManager from "@/components/level/LevelReviewHomeWorksManager.vue";
import LevelReviewParticipant from "./LevelReviewParticipant.vue";
import MarkedLink from "../MarkedLink.vue";
import Vue from "vue";
import {Video} from "@/types";
import headers from "@/config/header-templates";
import {links} from "@/config";

export default Vue.extend({
  name: "Level",
  props: {
    level: {
      type: Object as () => ActiveLevel
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    MarkedLink,
    LevelReviewRegistration,
    LevelReviewHomeWorksManager,
    LevelReviewParticipant,
    AssetLinks
  },
  computed: {
    reviews(): number[] {
      return this.$store.state.config.reviewsDuration;
    },
    reviewsActive(): number[] {
      return this.reviews.filter((r, i) => this.reviewIsWorthToPrepare(i) || this.level._activeReview === 1);
    },
    volunteer(): boolean {
      return this.$store.state.user.me.role.includes("volunteer");
    },
    config(): SystemConfig {
      return this.$store.state.config;
    },
    links(): {
      [name: string]: string
    } {
      return links;
    },
    lectures(): Lecture[] {
      return this.level.about.lectures;
    },
    materials(): {
      [index: string]: Material
    } {
      return this.$store.state.materials.all.other;
    },
    videos(): Video[] {
      return this.level.about.videos ? this.level.about.videos.parts : [];
    },
    bestReviewer(): Id {
      return this.level.bestReviewerId;
    },
    activeReviewTab: {
      get(): string {
        return this.activeTab || `${this.lastOpenedReviewTab}`;
      },
      set(value: string): void {
        this.activeTab = value;
      }
    },
    reviewMustHaveStarted(): boolean {
      return this.level.closed
          ? false
          : this.level.day >= this.config.daysToDoHomework + 1;
    },
    participantsListShouldBeHidden(): boolean {
      return this.level.day === this.config.daysToDoHomework + 1 && this.$dt.local().get("hour") < 9;
    },
    reviewersListAvailable(): boolean {
      return this.level.reviewers && this.level.reviewers.length > 0;
    },
    examineesListAvailable(): boolean {
      return this.level.examinees && this.level.examinees.length > 0;
    },
    lastOpenedReviewTab(): number {
      // if active review === 0 return 0
      // if active review === 1 and if level.homeworks[0] return 1
      // if active review === 2 and if level.homeworks[1] return 2
      // if active review === 3 and if level.homeworks[2] return 3
      // ...
      // else return 0
      if (this.level._activeReview === 0) {
        return 0;
      }
      if (this.level.closed || this.level._activeReview === this.config.reviewsDuration.length) {
        return this.level.homeworks.length - 1;
      }
      if (this.level.homeworks[this.level._activeReview - 1]) {
        return this.level._activeReview;
      }
      return 0;
    },
    // list opening datetime in format "(31.12.2019) в 09:00"
    participantsListOpenInfo(): string {
      // TODO: possibly rewrite this to the each level review starting day
      if (this.level.day > this.config.daysToDoHomework) {
        return this.$dt.fromSeconds(this.level.openDateTS).plus({
          // we add days as 24 hours * days, for correct work with DST switching
          hours: 24 * (this.level.day - 1)
        })
        // TODO: add configuration of this field to env or system config
            .set({ hour: 9 })
            .toFormat("(dd.LL.y) " + this.$t("at") + " t");
      }
      return "";
    },
    weekStatus(): string {
      const nearestReviewNumber: string = (this.level._activeReview + 1) + "_";
      const accepting = "_";
      const submitted = "_";
      const regday = this.level._regday ? "REGDAY_" : "";
      const registered = "_";
      const review = "_";
      const finished = "";
      const status: string = `${nearestReviewNumber}${accepting}${submitted}${regday}${registered}${review}${finished}`;
      return (headers.review_header as any)[status] || headers.review_header.DEFAULT;
    }
  },
  data() {
    return {
      selectedVideosPart: null,
      activeTab: ""
    };
  },
  methods: {
    reviewIsWorthToConduct(index: number): boolean {
      return this.level._activeReview >= index
        ? true
          : this.level._activeReview === index
            ? index > 0
              ? !!this.level.homeworks[index]
              : true
            : false;
    },
    reviewIsWorthToPrepare(index: number): boolean {
        return this.level.homeworks[index] // юзер сейчас сдаёт домашку
            ? true
            : this.level.homeworks[index - 1] && (this.level._activeReview === index - 1) // у юзера есть возможность сдавать домашку на след ревью
                ? true
                : !index; // сейчас только самое самое начало и индекс == 0

    },
    isUndefined(smth: any): boolean {
      return typeof smth === "undefined";
    },
    async openVideosModal(index: number, videos: VideoPart[]) {
      await this.$store.dispatch("modals/set", {
        type: "videos",
        visible: true,
        data: {
          index,
          videos,
          level: this.level.level,
        }
      });
    }
  }
});
