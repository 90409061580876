
import Vue from "vue";
import {ActiveLevel} from "@/types.d";
import MarkedLink from "@/components/MarkedLink.vue";
import {links} from "@/config";

export default Vue.extend({
  name: "LevelReviewRegistration",
  components: {
    MarkedLink
  },
  computed: {
    links() {
      return links;
    },
    registrationCompleted() {
      return this.availability && this.confirmedOnline;
    },
    registrationAvailable() {
      return this.registrationOpened && !this.registrationClosed;
    },
    registrationOpened() {
      return this.level.closed
          ? true
          : this.level._regday;
    },
    registrationClosed() {
      return this.level.closed ? true : this.level.day > (this.$store.state.config.reviewRegistrationPeriod.finishDay - 1);
    },
    systemInOnlineMode(): boolean {
      return this.$store.state.config.online;
    },
    confirmedOffline: {
      get(): boolean {
        return this.level.reviewRegistration.confirmed && !this.level.reviewRegistration.onlineRequest || false;
      },
      async set(confirmed: boolean): Promise<void> {
        await this.$store.dispatch("review/confirm", {
          confirmed,
          onlineRequest: false,
          level: this.level.level
        });
      }
    },
    confirmedOnline: {
      get(): boolean {
        return this.level.reviewRegistration.confirmed && this.level.reviewRegistration.onlineRequest || false;
      },
      async set(confirmed: boolean): Promise<void> {
        await this.$store.dispatch("review/confirm", {
          confirmed,
          onlineRequest: true,
          level: this.level.level
        });
      }
    },
    availability(): string {
      return this.level.reviewRegistration.availability;
    }
  },
  props: {
    level: {
      type: Object as () => ActiveLevel,
      required: true
    }
  },
  data() {
    return {
      newAvailability: "",
      editMode: false
    };
  },
  mounted() {
    this.newAvailability = this.availability;
  },
  methods: {
    cancelEdit() {
      this.editMode = false;
      this.newAvailability = this.availability;
    },
    copyAvailabilityToClipboard() {
      const el = document.createElement("textarea");
      el.value = this.newAvailability;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        type: "success",
        message: `${this.$t("copied")}`
      });
    },
    async saveAvailability() {
      await this.$store.dispatch("review/setAvailability", {
        message: this.newAvailability,
        level: this.level.level
      });
      this.editMode = false;
    }
  }
});
