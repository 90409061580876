
import Vue from "vue";
import ProfileInfo from "@/components/ProfileInfo.vue";
import WeekPlan from "@/components/WeekPlan.vue";
import MarkedLink from "@/components/MarkedLink.vue";
import EvaluateModal from "@/components/modals/EvaluateModal.vue";
import MeetUpFeedbackModal from "@/components/modals/MeetUpFeedbackModal.vue";
import VideosModal from "@/components/modals/VideosModal.vue";
import VideoModal from "@/components/modals/VideoModal.vue";
import HomeworkUploadModal from "@/components/modals/HomeworkUploadModal.vue";
import LevelInfo from "@/components/level/Level.vue";

import {ActiveLevel, Level, MeetUp, Now, SystemConfig, UserInfo} from "@/types.d";

import headers from "@/config/header-templates";
import {last} from "@/utils";
import { links } from "@/config";

export default Vue.extend({
  name: "UserDashboard",
  components: {
    ProfileInfo,
    WeekPlan,
    MarkedLink,
    EvaluateModal,
    MeetUpFeedbackModal,
    VideosModal,
    VideoModal,
    HomeworkUploadModal,
    Level: LevelInfo,
  },
  data() {
    return {
      activeLevels: [] as string[],
      evaluateModalVisible: false,
      loadingLevels: [] as boolean[],
      links
    };
  },
  computed: {
    config(): SystemConfig {
      return this.$store.state.config;
    },
    user(): UserInfo {
      return this.$store.state.user.me;
    },
    now(): Now {
      return this.$store.state.user.now;
    },
    meetUps(): MeetUp[] {
      return this.now.meetUps || [];
    },
    levels(): Array<Level | ActiveLevel> {
      return this.$store.state.levels.all;
    },
    titleForLevel(): ((level: Level | ActiveLevel) => string) {
      return (level: Level | ActiveLevel): string => {
        const statusType =
          level.closed || ((level.day || 0) > this.config.levelDuration) ? "CLOSED" :
            (level.day || 0) > this.config.daysToDoHomework  ? "REVIEW" :
              level._opened ? "OPENED" :
                level.day === 0 ? "NOT_OPENED" :
                  "DEFAULT";

        const lastReviewMarkClass = parseInt(last(level.avgMarks), 10) >= 3 ? "green" : "red";
        const levelStartDate = level.closed ? "" : this.$dt.fromSeconds((level as ActiveLevel).openDateTS).toFormat("dd.MM.y TT");
        const statusTemplate = headers.level_header[statusType]
          .replace("LEVEL_START_DATE", levelStartDate)
          .replace("LAST_REVIEW_AVG_MARK", `<span class=` + lastReviewMarkClass + `>` + last(level.avgMarks) + `</span>`)
          .replace("REVIEWS_AVG_MARKS",
            this.config.reviewsDuration.length > 1 ? // if this system supports multiple reviews
              JSON.stringify(level.avgMarks.slice(0, -1)) // remove last mark
                .slice(1, -1)  // crop brackets
            : ""
          );

        return `<div class="level-status">${statusTemplate}</div>`;
      };
    }
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch("user/get"),
      this.$store.dispatch("levels/get")
    ]);
    this.activeLevels = this.levels
      .filter((l: Level): boolean => l._opened)
      .map((l: Level): number => l.level)
      .map(String);

    if (this.now.vacation.status !== "is_passing") {
      setTimeout(() => {
        this.showNotifications();
      }, 100);
    }
  },
  methods: {
    async probablyRequestLevelInfo(e: string[]) {
      const levelsToLoad = e.map(Number)
          .filter((l: number): boolean => !(this.levels[l - 1] as ActiveLevel).about);
      levelsToLoad.forEach((l: number) => this.$set(this.loadingLevels, l - 1, true));
      const requests = levelsToLoad
        .map((l: number): Promise<any> =>
          this.$store.dispatch("levels/getExtendedLevel", l)
        );
      await Promise.all(requests);
      levelsToLoad.forEach((l: number) => this.$set(this.loadingLevels, l - 1, false));
    },
    showNotifications(): void {
      // TODO: add correct questions link
      const notifs = this.meetUps
        .map((m: MeetUp) =>
          (m.currentLevels || []).map((l) => ({
            type: "info",
            message: `${this.$t("notifications.meetUps." + m.type)}`
              .replace("THEORY_QUESTIONS_LINK", `<MarkedLink href="${l}" :level="l">${this.$t("t.notifications.questions")}</MarkedLink>`)
          }))
        )
        .reduce((acc, m) => [...acc, ...m], []) // flatten
        .filter((x) => x && x.message);           // clear empties

      if (this.user.frozen) {
        notifs.unshift({
          type: "warning",
          message: `${this.$t("notifications.review.frozen")}`
        });
      }
      if (this.levels.filter((l) => l._regday).length) {
        notifs.unshift({
          type: "info",
          message: `${this.$t("notifications.review.regday")}`
        });
      }

      if (!(this.user.discord)) {
        notifs.unshift({
          type: "warning",
          message: `${this.$t("notifications.general.discord")}`
        });
      }

      notifs.forEach((n) => {
        setTimeout(() => {
          this.$notify({
            title: "",
            customClass: n.type,
            type: (n.type as "error" | "success" | "warning" | "info"),
            message: n.message,
            duration: 0,
            dangerouslyUseHTMLString: true
          });
        }, 50);
      });
    }
  }
});
