
import Vue from "vue";
import DashboardUser from "@/views/DashboardUser.vue";
import DashboardVolunteer from "@/views/DashboardVolunteer.vue";

export default Vue.extend({
  name: "Dashboard",
  components: {
    DashboardUser,
    DashboardVolunteer
  },
  computed: {
    role(): string {
      return this.$store.state.user.me.role;
    },
    isConfigPresent(): boolean {
      return this.$store.state.config._filled;
    },
    useComponent(): string {
      return this.isConfigPresent ?
        (this.role.includes("volunteer") ? "DashboardVolunteer" :
          this.role.includes("student") ? "DashboardUser" : "")
        : "";
    }
  },
  async mounted() {
    await this.$store.dispatch("config/get");
    await this.$store.dispatch("review/getTheses");
  }
});
