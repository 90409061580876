
  import {conf} from "@/api";
  import MarkedLink from "../MarkedLink.vue";
  import Vue from "vue";

  export default Vue.extend({
    name: "AssetLinks",
    components: {
      MarkedLink
    },
    props: {
      assets: Array,
      divider: {
        type: String,
        default: ", "
      },
      level: {
        type: Number,
        default: 1
      },
      isText: {
        type: Boolean,
        default: true
      }
    },
  });
