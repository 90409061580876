export default {
  level_header: {
    VOLUNTEER: ":)",
    NOT_OPENED: "відкриється <strong>LEVEL_START_DATE</strong>",
    OPENED: "відкритий",
    REVIEW: "проходить рев'ю!",
    CLOSED: "Середня оцінка: <span>LAST_REVIEW_AVG_MARK</span> <span class='secondary-text'>(було: REVIEWS_AVG_MARKS)</span>",
    DEFAULT: "рівень в незрозумілому стані :/"
  },
  level_header_warnings: {
    0: "",
    1: "Отримано WARNINGS попередження",
    DEFAULT: "Отримано WARNINGS попереджень"
  },
  review_header: {
    "description0": "keys are formed as <review#>_<reviewState> + additional true flags...",
    "description1": "add _SUBMITTED if user submitted code, _REGDAY - if today is reg day, _REGISTERED - if review not started and user registered",
    "1_ACCEPTING": "код не залит, дедлайн: <strong>SUBMISSION_DEADLINE_DATE_TIME</strong>",
    "1_ACCEPTING_SUBMITTED": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), ждите <strong>REVIEW_REGISTRATION_WEEKDAY_DATE</strong> для записи на ревью",
    "1_ACCEPTING_REGDAY": "код не залит, дедлайн: <strong>SUBMISSION_DEADLINE_DATE_TIME</strong> (близко!)",
    "1_ACCEPTING_SUBMITTED_REGDAY": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), <strong>запишитесь сегодня на ревью!</strong>",
    "1_ACCEPTING_SUBMITTED_REGDAY_REGISTERED": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), <strong>вы участвуете в ревью :)</strong>",
    "1_SUBMITTED_REGISTERED_REVIEW": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), <strong>вы участвуете в ревью</strong>",
    "1_SUBMITTED_FINISHED": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), оценки выставлены, ревью закрыто",
    "description2": "after review #1 user is _REGISTERED for all the next reviews anyway, or his level will be failed :)",
    "N_PENDING": "приём кода откроется после старта N-1 ревью",
    "N_ACCEPTING": "если вам не нравятся оценки за N-1 ревью, залейте код с исправлениями, дедлайн: <strong>SUBMISSION_N_DEADLINE_DATE_TIME</strong>",
    "N_ACCEPTING_SUBMITTED": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), ждите начала ревью: <strong>SUBMISSION_N_DEADLINE_DATE_TIME</strong>",
    "N_SUBMITTED_REVIEW": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), <strong>вы участвуете в ревью</strong>",
    "N_REVIEW": "новая версия не залита (ваши оценки дублируются), <strong>вы участвуете в ревью</strong>",
    "N_SUBMITTED_FINISHED": "код залит (LINK_DOWNLOAD_LAST_SUBMISSION), оценки выставлены, ревью закрыто",
    "N_FINISHED": "код не залит, вашему коду оценки продублированы, оценки другим выставлены, ревью закрыто",
    "DEFAULT": "баг - ревью в непонятном состоянии :/"
  }
};
