
  import Vue from "vue";
  import ProfileInfo from "@/components/ProfileInfo.vue";
  import WeekPlan from "@/components/WeekPlan.vue";
  import MarkedLink from "@/components/MarkedLink.vue";
  import EvaluateModal from "@/components/modals/EvaluateModal.vue";
  import VideosModal from "@/components/modals/VideosModal.vue";
  import VideoModal from "@/components/modals/VideoModal.vue";
  import MaterialsModal from "@/components/modals/MaterialsModal.vue";
  import LevelInfo from "@/components/level/Level.vue";
  import AssetLinks from "@/components/level/LevelAssetLinks.vue";
  import LevelReviewRegistrationVolunteer from "@/components/level/LevelReviewRegistrationVolunteer.vue";

  import {Lecture, Level, ReviewRegistration, SystemConfig, UserInfo, VideoPart} from "@/types.d";

  import headers from "@/config/header-templates";

  export default Vue.extend({
    name: "UserDashboard",
    components: {
      ProfileInfo,
      WeekPlan,
      MarkedLink,
      AssetLinks,
      EvaluateModal,
      VideoModal,
      VideosModal,
      MaterialsModal,
      Level: LevelInfo,
      LevelReviewRegistrationVolunteer
    },
    methods: {
      async openVideosModal(index: number, videos: VideoPart[], level: number) {
        await this.$store.dispatch("modals/set", {
          type: "videos",
          visible: true,
          data: {
            index,
            videos,
            level,
          }
        });
      }
    },
    data() {
      return {
        activeLevels: [] as string[],
        evaluateModalVisible: false
      };
    },
    computed: {
      config(): SystemConfig {
        return this.$store.state.config;
      },
      user(): UserInfo {
        return this.$store.state.user.me;
      },
      materials(): Lecture[] {
        return this.$store.state.user.materials;
      },
      registrationInfo(): {
        current: ReviewRegistration,
        future: ReviewRegistration
      } {
        return this.$store.state.user.reviewRegistration || {
          current: {},
          future: {}
        };
      },
      levels(): Level[] {
        return this.$store.state.levels.all;
      },
      levelStatus(): string {
        return headers.level_header.VOLUNTEER;
      }
    },
    async created() {
      await Promise.all([
        this.$store.dispatch("user/get", true),
        this.$store.dispatch("user/getMaterials")
      ]);
      this.activeLevels = (this.levels as Level[])
        .map((l: Level): number => l.level)
        .map(String);

      if (this.user.level > 10 && localStorage.getItem("gotSticker") !== "true") {
        this.$notify({
          message: `${this.$t("volunteer.sticker.message")}`,
          title: `${this.$t("volunteer.sticker.title")}`,
          duration: 0,
          customClass: "success",
          type: "success"
        });
        localStorage.setItem("gotSticker", "true");
      }
    },
  });
