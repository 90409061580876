
import Vue from "vue";
import {ActiveLevel, Material} from "@/types.d";
import {DateTime} from "luxon";

import DownloadCodeLink from "./LevelReviewDownloadHomework.vue";
import MarkedLink from "../MarkedLink.vue";
import { links } from "@/config";

export default Vue.extend({
    name: "LevelReviewHomeWorksManager",
    components: {
      DownloadCodeLink,
      MarkedLink
    },
    props: {
      level: {
        type: Object as () => ActiveLevel,
        required: true
      },
      reviewIndex: {
        type: Number,
        required: true
      }
    },
    computed: {
      links() {
        return links;
      },
      isRegDay() {
        return this.reviewIndex === 0 ? this.level._regday : !this.reviewRegistrationClosed;
      },
      reviews(): number[] {
        return this.$store.state.config.reviewsDuration;
      },
      firstDeadlineDay(): number {
        return this.$store.state.config.daysToDoHomework;
      },
      firstDeadlineDate(): DateTime {
        const daysToDoHomework = Date.now() - (new Date("2023-12-27")).getTime() < 0 ? this.firstDeadlineDay + 6 : this.firstDeadlineDay - 1;
        return this.$dt
          .fromSeconds(+this.level.openDateTS)
          .plus({days: daysToDoHomework});
      },
      materials(): {
        [index: string]: Material
      } {
        return this.$store.state.materials.all.other;
      },
      homeworkUploaded(): boolean {
        return !!this.level.homeworks[this.reviewIndex];
      },
      reviewRegistrationClosed(): boolean {
        return this.level.closed ? true : this.level.day > (this.firstDeadlineDay + this.previousReviewDuration);
      },
      // returns string like вівторок, 31 жовтня
      uploadDeadlineFormatted(): string {
        return this.firstDeadlineDate.plus({
          days: this.previousReviewDuration
        }).endOf("day").toLocaleString({month: "long", day: "numeric", weekday: "long"});
      },
      uploadDeadlineTime(): string {
        return this.firstDeadlineDate.plus({
          days: this.previousReviewDuration
        }).endOf("day").toFormat("TT");
      },
      previousReviewDuration(): number {
        return this.reviewIndex > 0 ? this.reviews[this.reviewIndex - 1] : 0;
      }
    },
    methods: {
      async showModal() {
        await this.$store.dispatch("modals/set", {
          type: "homeworkUpload",
          visible: true,
          data: {
            level: this.level.level,
            review: this.reviewIndex + 1
          }
        });
      }
    }
  });
