
import Vue from "vue";
import {links} from "@/config";

export default Vue.extend({
  name: "VacationModal",
  computed: {
    vacation(): ({
      startTS: number,
      endTS: number,
      status: string
    }) {
      return this.$store.state.user.now.vacation;
    },
    from(): string {
      return this.$dt.fromSeconds(+this.vacation.startTS).toFormat("DDD");
    },
    to(): string {
      return this.$dt.fromSeconds(+this.vacation.endTS).toFormat("DDD");
    },
    vacationPeriod(): string {
      return `${this.$t("from")} ${this.from} ${this.$t("to")} ${this.to}`;
    },
    currentLevel(): number {
      return this.$store.state.user.me.level;
    },
    links(): {
      [index: string]: string
    } {
      return links;
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    async toggleVacation() {
      this.visible = false;
      await this.$store.dispatch("user/toggleVacation", {
        wantVacation: !["cancellable", "fixed"].includes(this.vacation.status)
      });
    }
  }
});
