
import Vue from "vue";
import {Id} from "@/types.d";
import config from "@/config";
import MarkedLink from "../MarkedLink.vue";

export default Vue.extend({
  name: "LevelReviewDownloadHomework",
  props: {
    homeworkId: {
      type: String,
      required: true
    },
    ownerId: {
      type: Number as () => Id
    }
  },
  components: {
    MarkedLink
  },
  computed: {
    userId(): Id {
      return this.$store.state.user.me.id;
    },
    downloadLink(): string {
      return `${config.baseURL}${config.endpoints.download_homework}?ownerId=${this.ownerId || this.userId}&homeworkId=${this.homeworkId}`;
    }
  }
});
