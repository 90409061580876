
import Vue from "vue";
import {Mark} from "@/types.d";

export default Vue.extend({
  name: "LevelReviewTable",
  props: {
    marks: {
      type: Array as () => Mark[],
      required: true
    },
    reviewClosed: {
      type: Boolean,
      default: () => true
    },
    reviewType: {
      type: String,
      default: () => "set"
    },
    level: {
      type: Number,
      default(): number {
        return this.$store.state.user.me.level;
      }
    }
  },
  computed: {
    tasksAmount(): number {
      return this.$store.state.config.tasksPerLevel[this.level - 1]; // minus 1 to get index of level
    },
    marksData(): Mark[] {
      return Array(this.tasksAmount)
        .fill(0)
        .map(
          (_, i: number): Mark => this.marks.find((m) => m.task === i + 1) || ({
            mark: 0,
            message: "",
            updatedAtTS: 0,
            task: i + 1
          })
        );
    }
  },
  methods: {
    markChangeIsAvailable(ts: number) {
      return this.reviewType === "set"
          ? !this.reviewClosed
            && this.$dt.local().toSeconds() - ts < +(process.env.VUE_APP_MARK_CHANGE_PERIOD_MINUTES || 0) * 60 // less than X minutes passed
          : false ;
    },
    tableRowClassName({ row: { mark } }: { row: { mark: number } }) {
      if (mark > 0 && mark <= 2) {
        return "error-row";
      }
      return "";
    }
  }
});
