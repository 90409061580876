
  import MarkedLink from "../MarkedLink.vue";
  import Vue from "vue";
  import {Material} from "@/types";

  export default Vue.extend({
    name: "MaterialsModal",
    components: {
      MarkedLink
    },
    computed: {
      materials(): {
        title: string;
        files: Material[] | Material[][]
      } {
        return this.$store.state.materials.all.materials;
      }
    },
    data() {
      return {
        visible: false
      };
    },
    async mounted() {
      await this.$store.dispatch("materials/get");
    }
  });
