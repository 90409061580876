
  import Vue from "vue";

  export default Vue.extend({
    name: "ReviewTypeModal",
    data() {
      return {
        typeOfReview: [
          {title: this.$t("reviewType.offline"), value: "offline"},
          {title: this.$t("reviewType.online"), value: "online"},
          {title: this.$t("reviewType.disabled"), value: "disabled", disabled: true}
        ],
        radioType: "",
      };
    },
    computed: {
      visible: {
        get(): boolean {
          return this.$store.state.modals.review.visible;
        },
        async set(value: boolean): Promise<void> {
          await this.$store.dispatch("modals/set", {
            type: "review",
            visible: value
          });
        }
      },
      title(): string {
        return `${this.$t("reviewType.title")}`;
      },
      info() {
        return this.$store.state.modals.review.data;
      }
    },

    methods: {
      async begin() {
        await this.$store.dispatch("review/setReviewPlace", {
          level: +this.info.authorLevel,
          online: this.radioType === "online",
          examineeId: +this.info.receiverId,
          reviewNumber: +this.info.reviewNumber
        });
        this.visible = false;
      }
    }
  });
